<template>
  <AppHeader />
  <router-view></router-view>
  <AppFooter />
</template>

<script>
import { initCart } from './requestService.js';
import AppHeader from "@/components/UI/AppHeader.vue";
import AppFooter from "@/components/UI/AppFooter.vue";
export default {
  name: "App",
  components: {
    AppHeader,
    AppFooter,
  },
	created(){
		initCart();
	}
};
</script>

<style>
*,
*::before,
*::after {
  box-sizing: border-box;
}
body {
  font-family: "Roboto", sans-serif;
  color: #fff;
}
a {
  text-decoration: none;
  color: inherit;
}
.default-btn {
  background-color: #fff;
  border: none;
  border-radius: 40px;
  color: #373975;
  cursor: pointer;
  font-weight: Bold;
  font-size: 12px;
  letter-spacing: 1.75px;
  line-height: 14px;
  padding: 18px 80px;
  text-transform: uppercase;
}
.default-btn:hover {
  opacity: 0.9;
}
</style>
